import React, { useMemo, useContext } from "react";
import { format } from "date-fns";
import { useTable, useSortBy, useRowSelect } from "react-table";
import { IoMdArrowDropup, IoMdArrowDropdown } from "react-icons/io";
import { toast } from "react-toastify";
import { Columns } from "./Columns";
import EditDocumentPopup from "./EditDocumentPopup";
import DeleteDocumentPopup from "./DeleteDocumentPopup";
import Loading from "../material-ui/Loading";
import CheckBox from "./CheckBox";
import Dropdown from "./Dropdown";
import { fileIcon } from "../common/FileIcons";
import { AuthContext } from "../../context/Auth/AuthContext";
import markDocumentsAsFavourite from "../../api/addBulkFavouriteDocument";
import removeDocumentsFromFavourite from "../../api/removeBulkFavouriteDocuments";
import markDocumentAsFavourite from "../../api/addFavouriteDocument";
import removeDocumentFromFavourite from "../../api/removeFavouriteDocument";
import "../../styles/Table.css";

const Table = ({
	loading,
	openFilter,
	addDocumentTab,
	changeDocumentFavouriteStateFunc,
	setDocumentUpdatedToTrue,
	setDocumentDeletedToTrue,
	allFavourite,
	documentTableData,
	categoryOptions,
}) => {
	const columns = useMemo(() => Columns, []);
	const data = useMemo(
		() =>
			documentTableData.map((table) => {
				return table;
			}),
		[documentTableData]
	);

	const { token, isAdmin } = useContext(AuthContext);

	const onStarredHeaderCheck = async (e) => {
		let docsID = documentTableData.map((doc) => {
			return `'${doc.documentID}'`;
		});

		if (e.target.checked) {
			const markDocumentsAsFavouriteResponse = await markDocumentsAsFavourite({
				token: token,
				id: docsID,
			});

			if (markDocumentsAsFavouriteResponse.code === 200) {
				changeDocumentFavouriteStateFunc();
				toast.success("Documents are added to favourite list", {
					position: "top-center",
					autoClose: 3000,
					theme: "dark",
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
			}
		} else {
			const removeDocumentsFromFavouriteResponse =
				await removeDocumentsFromFavourite({
					token: token,
					id: docsID,
				});

			if (removeDocumentsFromFavouriteResponse.code === 200) {
				changeDocumentFavouriteStateFunc();
				toast.success("Documents are removed from favourite list", {
					position: "top-center",
					autoClose: 3000,
					theme: "dark",
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
			}
		}
	};

	const onDocumentCheck = async (e, row) => {
		console.log("hii");
		if (row.original.favourite === "0") {
			console.log("hiiiiii");

			const markDocumentAsFavouriteResponse = await markDocumentAsFavourite({
				token: token,
				id: row.original.documentID,
			});

			if (markDocumentAsFavouriteResponse.code === 200) {
				changeDocumentFavouriteStateFunc();
				toast.success("Document is added to favourite list", {
					position: "top-center",
					autoClose: 3000,
					theme: "dark",
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
			}
		}

		if (row.original.favourite === "1") {
			const removeDocumentFromFavouriteResponse =
				await removeDocumentFromFavourite({
					token: token,
					id: row.original.documentID,
				});

			if (removeDocumentFromFavouriteResponse.code === 200) {
				changeDocumentFavouriteStateFunc();
				toast.success("Document is removed from favourite list", {
					position: "top-center",
					autoClose: 3000,
					theme: "dark",
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
			}
		}
	};

	const tableHooks = (hooks) => {
		hooks.visibleColumns.push((columns) => [
			...columns,
			...(isAdmin !== "2"
				? [
						{
							Header: "Action",
							Cell: ({ row }) => (
								<>
									<EditDocumentPopup
										setDocumentUpdatedToTrue={setDocumentUpdatedToTrue}
										tableValues={row.values}
										categoryOptions={categoryOptions}
									/>
									<DeleteDocumentPopup
										setDocumentDeletedToTrue={setDocumentDeletedToTrue}
										id={row.values.documentID}
										tableValues={row.values}
									/>
								</>
							),
						},
				  ]
				: []),
		]);
	};

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
		selectedFlatRows,
		getToggleAllRowsSelectedProps,
	} = useTable(
		{
			columns: columns,
			data: data,
			initialState: {
				hiddenColumns: [
					"documentID",
					"favourite",
					"document_desc",
					"bg_color",
					"category",
					"name",
					"visibility",
				],
			},
		},
		useSortBy,
		tableHooks,
		useRowSelect,
		(hooks) => {
			hooks.visibleColumns.push((columns) => {
				return [
					{
						id: "selection",
						Cell: ({ row }) => (
							<>
								<CheckBox {...row.getToggleRowSelectedProps()} />
							</>
						),
					},
					{
						id: "starred",
						Cell: ({ row }) => (
							<>
								<input
									className="star-checkbox"
									type="checkbox"
									checked={row.original.favourite === "1" ? true : false}
									onChange={(e) => onDocumentCheck(e, row)}
								/>
							</>
						),
					},
					...columns,
				];
			});
		}
	);

	const selectedFlatRowsID = selectedFlatRows.map(
		(row) => `'${row.values.documentID}'`
	);
	const documentTableDataID = documentTableData.map((row) => "tableData");

	const isEntireTableRowsSelected =
		selectedFlatRowsID.length === documentTableDataID.length;

	const SelectionHeader = ({ getToggleAllRowsSelectedProps }) => {
		return (
			<div className="bulk-func">
				<CheckBox {...getToggleAllRowsSelectedProps()} />
				<Dropdown
					selectedRows={selectedFlatRowsID}
					isEntireTableRowsSelected={isEntireTableRowsSelected}
					setDocumentDeletedToTrue={setDocumentDeletedToTrue}
				/>
			</div>
		);
	};

	const StarredHeader = () => {
		return (
			<input
				type="checkbox"
				className="star-checkbox"
				checked={allFavourite}
				onChange={onStarredHeaderCheck}
			/>
		);
	};

	return (
		<div className={!openFilter ? "table-content" : "resized-table-content"}>
			{!loading ? (
				<table className="documents-table" {...getTableProps()}>
					<thead className="documents-table-header">
						{headerGroups.map((headerGroup, index) => (
							<tr
								className="documents-table-header-row"
								key={index}
								{...headerGroup.getHeaderGroupProps()}
							>
								{headerGroup.headers.map((column, index) => {
									return (
										<th
											{...column.getHeaderProps(column.getSortByToggleProps())}
											className="documents-table-header-data"
											key={index}
										>
											<span className="document-table-select-row">
												{column.id == "selection" ? (
													<SelectionHeader
														getToggleAllRowsSelectedProps={
															getToggleAllRowsSelectedProps
														}
													/>
												) : column.id == "starred" ? (
													<StarredHeader />
												) : (
													<span
														style={{
															display: "flex",
															justifyContent: "center",
															gap: "5px",
														}}
													>
														{column.render("Header")}
														<span
															style={{
																display: "flex",
																justifyContent: "center",
																gap: "5px",
																fontSize: "16px",
															}}
														>
															{column.isSorted ? (
																column.isSortedDesc ? (
																	<IoMdArrowDropdown />
																) : (
																	<IoMdArrowDropup />
																)
															) : (
																""
															)}
														</span>
													</span>
												)}
											</span>
										</th>
									);
								})}
							</tr>
						))}
					</thead>
					<tbody className="documents-table-body" {...getTableBodyProps()}>
						{rows.map((row, index) => {
							prepareRow(row);
							return (
								<tr
									key={index}
									className="document-table-body-row"
									{...row.getRowProps()}
								>
									{row.cells.map((cell, index) => {
										switch (cell.column.Header) {
											case "File":
												return (
													<td key={index} className="file-icon">
														<span
															style={{
																cursor:
																	cell.row.values.extension === "pdf" ||
																	cell.row.values.extension === "jpg" ||
																	cell.row.values.extension === "jpeg" ||
																	cell.row.values.extension === "png"
																		? "pointer"
																		: "context-menu",
															}}
															onClick={() =>
																cell.row.values.extension === "pdf" ||
																cell.row.values.extension === "jpg" ||
																cell.row.values.extension === "jpeg" ||
																cell.row.values.extension === "png"
																	? addDocumentTab(cell.row.values)
																	: null
															}
														>
															{fileIcon[cell.value]}
														</span>
													</td>
												);
											case "Case ID":
												return (
													<td key={index}>
														{cell.value !== "" ? cell.value : "N/A"}
													</td>
												);
											case "Name":
												return (
													<td key={index}>
														<span
															style={{
																cursor:
																	cell.row.values.extension === "pdf" ||
																	cell.row.values.extension === "jpg" ||
																	cell.row.values.extension === "jpeg" ||
																	cell.row.values.extension === "png"
																		? "pointer"
																		: "context-menu",
															}}
															onClick={() =>
																cell.row.values.extension === "pdf" ||
																cell.row.values.extension === "jpg" ||
																cell.row.values.extension === "jpeg" ||
																cell.row.values.extension === "png"
																	? addDocumentTab(cell.row.values)
																	: null
															}
														>
															{cell.value}
														</span>
													</td>
												);
											case "Category":
												return (
													<td key={index}>
														<p
															className="category"
															style={{
																background: `${
																	cell.row.values.bg_color ||
																	"rgb(203, 190, 196)"
																}`,
															}}
														>
															{cell.row.values.badge || "Other"}
														</p>
													</td>
												);

											case "Modified By":
												return (
													<td key={index}>{cell.value ? cell.value : "N/A"}</td>
												);

											case "Uploaded":
												return (
													<td key={index}>
														{cell.value !== "0000-00-00" &&
														cell.value !== "0000-00-00 00:00:00"
															? format(new Date(cell.value), "MM-dd-yyyy HH:mm")
															: "N/A"}
													</td>
												);

											case "Modified At":
												return (
													<td key={index}>
														{cell.value !== "0000-00-00" &&
														cell.value !== "0000-00-00 00:00:00"
															? format(new Date(cell.value), "MM-dd-yyyy HH:mm")
															: "N/A"}
													</td>
												);

											case "Tax Year":
												return (
													<td key={index}>
														{cell.value !== "" ? cell.value : "N/A"}
													</td>
												);

											default:
												return (
													<td key={index} {...cell.getCellProps()}>
														{cell.render("Cell")}
													</td>
												);
										}
									})}
								</tr>
							);
						})}
					</tbody>
				</table>
			) : (
				<Loading />
			)}
		</div>
	);
};

export default Table;
