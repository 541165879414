import baseUrl from "../config/baseUrl";

const editDocument = async ({
	token,
	user_id,
	case_number,
	document_id,
	document_title,
	document_desc,
	tax_year,
	category,
	visibility,
}) => {
	const formData = new FormData();
	formData.append("userIdd", user_id);
	formData.append("case_number", case_number);
	formData.append("document_id", document_id);
	formData.append("document_title", document_title);
	formData.append("document_desc", document_desc);
	formData.append("tax_year", tax_year);
	formData.append("category", category);
	formData.append("visibility", visibility);

	try {
		const response = await fetch(`${baseUrl}/apireact/update_documents`, {
			method: "POST",
			headers: {
				Accept: "application/json",
				Authorization: `Bearer ${token}`,
			},
			body: formData,
		});
		const data = await response.json();
		return data;
	} catch (error) {
		return error;
	}
};

export default editDocument;
