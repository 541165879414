import React, { useContext } from "react";
import { Controller } from "react-hook-form";
import { Grid, TextField } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputUI from "../material-ui/InputUI";
import { AuthContext } from "../../context/Auth/AuthContext";

const EditDocumentForm = ({
	register,
	control,
	errors,
	tableValues,
	categoryOptions,
}) => {
	const { isAdmin } = useContext(AuthContext);

	console.log(tableValues);

	return (
		<Grid className="edit-document">
			<div className="edit-document-form">
				<div className="field_wrapper">
					<div className="field">
						<div className="input-field">
							<Controller
								name="documentTitle"
								defaultValue={tableValues.title}
								control={control}
								render={({ field: { onChange, value } }) => (
									<InputUI
										name="documentTitle"
										label="Document Title"
										type="text"
										value={value ? value : ""}
										setValue={onChange}
										variant="outlined"
										register={register}
									/>
								)}
							/>
						</div>
						<span className="error-message">
							{errors["documentTitle"]?.message}
						</span>
					</div>
				</div>
				{isAdmin === "1" ? (
					<div className="field_wrapper">
						<div className="field">
							<div className="input-field">
								<Controller
									name="caseID"
									defaultValue={tableValues.case_number}
									control={control}
									render={({ field: { onChange, value } }) => (
										<TextField
											name="caseID"
											label="Case ID"
											type="text"
											value={value ? value : ""}
											onChange={onChange}
											variant="outlined"
										/>
									)}
								/>
							</div>
							<span className="error-message">{errors["caseID"]?.message}</span>
						</div>
					</div>
				) : null}
				<div className="field_wrapper">
					<div className="field">
						<div className="input-field">
							<Controller
								name="documentDescription"
								defaultValue={tableValues.document_desc}
								control={control}
								render={({ field: { onChange, value } }) => (
									<InputUI
										name="documentDescription"
										label="Document Description"
										type="text"
										value={value ? value : ""}
										setValue={onChange}
										variant="outlined"
										register={register}
									/>
								)}
							/>
						</div>

						<span className="error-message">
							{errors["documentDescription"]?.message}
						</span>
					</div>
				</div>
				<div className="field_wrapper">
					<div className="field">
						<div className="input-field">
							<Controller
								name="taxYear"
								defaultValue={tableValues.tax_year}
								control={control}
								render={({ field: { onChange, value } }) => (
									<TextField
										name="taxYear"
										label="Tax Year"
										type="text"
										value={value ? value : ""}
										onChange={onChange}
										variant="outlined"
									/>
								)}
							/>
						</div>
						<span className="error-message">{errors["taxYear"]?.message}</span>
					</div>
				</div>
				<div className="field_wrapper">
					<div className="field">
						<FormControl className="edit-doc-select-option" fullWidth>
							<InputLabel className="edit-doc-select-label" id="select-label">
								Category
							</InputLabel>
							<Controller
								control={control}
								name="category"
								defaultValue={tableValues.category}
								render={({ field: { onChange, value } }) => (
									<Select
										name="category"
										labelId="select-label"
										variant="outlined"
										id="simple-select"
										value={value}
										onChange={onChange}
										label="Category"
										{...register("category")}
									>
										{categoryOptions.map((option) => (
											<MenuItem key={option} value={option}>
												{option}
											</MenuItem>
										))}
									</Select>
								)}
							/>
						</FormControl>
						<span className="error-message">{errors["category"]?.message}</span>
					</div>
				</div>
				<div className="field_wrapper">
					<div className="field">
						<FormControl
							className="edit-doc-select-option"
							fullWidth
							sx={{ marginTop: "25px" }}
						>
							<InputLabel className="edit-doc-select-label" id="select-label">
								Field Visibility
							</InputLabel>
							<Controller
								control={control}
								name="fieldVisibility"
								defaultValue={tableValues?.visibility}
								render={({ field: { onChange, value } }) => (
									<Select
										name="fieldVisibility"
										labelId="select-label"
										variant="outlined"
										id="simple-select"
										value={value}
										onChange={onChange}
										label="Field Visibility"
									>
										<MenuItem value={1}>Yes</MenuItem>
										<MenuItem value={0}>No</MenuItem>
									</Select>
								)}
							/>
						</FormControl>
						<span className="error-message">{errors["category"]?.message}</span>
					</div>
				</div>
			</div>
		</Grid>
	);
};

export default EditDocumentForm;
