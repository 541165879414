import React, { useState, useContext } from "react";
import { toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { MdModeEdit } from "react-icons/md";
import { RiCloseCircleFill } from "react-icons/ri";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import EditDocumentForm from "./EditDocumentForm";
import ButtonUI from "../material-ui/ButtonUI";
import editDocument from "../../api/editDocument";
import { AuthContext } from "../../context/Auth/AuthContext";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	"& .MuiDialogContent-root": {
		padding: theme.spacing(2),
	},
	"& .MuiDialogActions-root": {
		padding: theme.spacing(1),
	},
}));

const BootstrapDialogTitle = (props) => {
	const { children, onClose, ...other } = props;

	return (
		<DialogTitle sx={{ m: 0, p: 2 }} {...other}>
			{children}
			{onClose ? (
				<IconButton
					aria-label="close"
					onClick={onClose}
					sx={{
						position: "absolute",
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<RiCloseCircleFill />
				</IconButton>
			) : null}
		</DialogTitle>
	);
};

BootstrapDialogTitle.propTypes = {
	children: PropTypes.node,
	onClose: PropTypes.func.isRequired,
};

const editDocumentFormSchema = yup.object().shape({
	documentTitle: yup.string().required("Document Title is required field"),
	documentDescription: yup
		.string()
		.required("Document Description is required field"),
	category: yup.string().required("Category is required field"),
	fieldVisibility: yup.string(),
});

const EditDocumentPopup = ({
	setDocumentUpdatedToTrue,
	tableValues,
	categoryOptions,
}) => {
	const [open, setOpen] = useState(false);
	const { token, userID } = useContext(AuthContext);

	const {
		register,
		handleSubmit,
		control,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(editDocumentFormSchema),
	});

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const onEditDocumentFormSubmit = async (editDocumentFormData) => {
		const editDocumentFormResponse = await editDocument({
			token: token,
			user_id: userID,
			case_number: editDocumentFormData.caseID,
			document_id: tableValues.documentID,
			document_title: editDocumentFormData.documentTitle,
			document_desc: editDocumentFormData.documentDescription,
			tax_year:
				editDocumentFormData.taxYear === undefined
					? ""
					: editDocumentFormData.taxYear,
			category: editDocumentFormData.category,
			visibility: editDocumentFormData?.fieldVisibility,
		});

		if (editDocumentFormResponse.code === 200) {
			setDocumentUpdatedToTrue();
			setOpen(false);

			toast.success("Document is updated successfully", {
				position: "top-center",
				autoClose: 3000,
				theme: "dark",
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
		}
	};

	return (
		<div className="dialog-modal">
			<ButtonUI
				formButton={false}
				variant="outlined"
				buttonText={<MdModeEdit />}
				style={{
					backgroundColor: "#17a2b8",
					color: " white",
					marginRight: "5px",
				}}
				onClickFunc={handleClickOpen}
			/>
			<BootstrapDialog
				onClose={handleClose}
				aria-labelledby="customized-dialog-title"
				open={open}
			>
				<BootstrapDialogTitle
					id="customized-dialog-title"
					className="edit-doc-modal"
					onClose={handleClose}
				>
					<div className="popup-header-svg-icon">
						<MdModeEdit />
					</div>
					<div className="popup-header-title">Edit Document</div>
				</BootstrapDialogTitle>
				<DialogContent dividers>
					<EditDocumentForm
						register={register}
						control={control}
						errors={errors}
						tableValues={tableValues}
						categoryOptions={categoryOptions}
					/>
				</DialogContent>
				<DialogActions>
					<div className="popup_submit_btn">
						<ButtonUI
							variant="contained"
							buttonText="Submit"
							handleSubmit={handleSubmit}
							onFormSubmit={onEditDocumentFormSubmit}
						/>
					</div>
				</DialogActions>
			</BootstrapDialog>
		</div>
	);
};

export default EditDocumentPopup;
