import baseUrl from "../config/baseUrl";

const addDocument = async ({
	token,
	case_number,
	document_title,
	document_desc,
	tax_year,
	category,
	document_name,
	visibility,
	source,
}) => {
	const formData = new FormData();
	formData.append("case_number", case_number);
	formData.append("document_title", document_title);
	formData.append("document_desc", document_desc);
	formData.append("tax_year", tax_year);
	formData.append("category", category);
	formData.append("document_name", document_name);
	formData.append("visibility", visibility);
	formData.append("source", source);

	try {
		const response = await fetch(`${baseUrl}/apireact/upload_documents`, {
			method: "POST",
			headers: {
				Accept: "application/json",
				Authorization: `Bearer ${token}`,
			},
			body: formData,
		});
		const data = await response.json();
		return data;
	} catch (error) {
		return error;
	}
};

export default addDocument;
