import React, { useState, useContext } from "react";
import { toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { AiFillCloseCircle } from "react-icons/ai";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputUI from "../material-ui/InputUI";
import ButtonUI from "../material-ui/ButtonUI";
import { AuthContext } from "../../context/Auth/AuthContext";
import addDocument from "../../api/addDocument";
import "../../styles/AddDocument.css";
import addDoc from "../../images/add-document.png";
import { TextField } from "@mui/material";

const SUPPORTED_FORMATS = [
	"image/jpg",
	"image/jpeg",
	"image/gif",
	"image/png",
	"application/pdf",
];

const addDocumentFormSchema = yup.object().shape({
	caseID: yup.string().required("Case ID is required field"),
	documentTitle: yup.string().required("Document Title is required field"),
	documentDescription: yup
		.string()
		.required("Document Description is required field"),
	addDocCategories: yup.string().required("Category is required field"),
	taxYear: yup.string(),
	fieldVisibility: yup.string(),
	uploadSource: yup.string(),
	fileUpload: yup
		.mixed()
		.test("required", "Please select a file", (value) => {
			return value && value.length > 0;
		})
		.test("type", "We only support images and pdf", (value) => {
			return value && SUPPORTED_FORMATS.includes(value[0]?.type);
		})
		.test(
			"fileSize",
			"Please upload file having size less than 5MB",
			(value) => {
				return value && value[0]?.size <= 5000000;
			}
		),
});

const AddDocument = ({
	onAddDocumentButtonClick,
	setDocumentAddedToTrue,
	categoryOptions,
}) => {
	const [loading, setLoading] = useState(false);
	const { token } = useContext(AuthContext);

	const {
		register,
		handleSubmit,
		control,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(addDocumentFormSchema),
	});

	const onAddDocumentFormSubmit = async (addDocumentFormData) => {
		console.log(addDocumentFormData);
		setLoading(true);

		toast.info("Document is being added", {
			position: "top-center",
			autoClose: 3000,
			theme: "dark",
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});

		const addDocumentFormResponse = await addDocument({
			token: token,
			case_number: addDocumentFormData.caseID,
			document_title: addDocumentFormData.documentTitle,
			document_desc: addDocumentFormData.documentDescription,
			category: addDocumentFormData.addDocCategories,
			tax_year: addDocumentFormData.taxYear,
			document_name: addDocumentFormData.fileUpload[0],
			visibility: addDocumentFormData?.fieldVisibility,
			source: addDocumentFormData?.uploadSource,
		});

		if (addDocumentFormResponse?.code === 200) {
			setLoading(false);
			onAddDocumentButtonClick();
			setDocumentAddedToTrue();
			toast.success("Document is added successfully", {
				position: "top-center",
				autoClose: 3000,
				theme: "dark",
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
		}

		if (
			addDocumentFormResponse?.errors?.case_number[0] ||
			addDocumentFormResponse?.errors?.document_title[0] ||
			addDocumentFormResponse?.errors?.document_desc[0] ||
			addDocumentFormResponse?.errors?.tax_year[0] ||
			addDocumentFormResponse?.errors?.category[0] ||
			addDocumentFormResponse?.errors?.document_name[0]
		) {
			setLoading(false);
			toast.error("Every field is required", {
				position: "bottom-right",
				autoClose: 5000,
				theme: "dark",
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
		}
	};

	return (
		<div className="add-document-page">
			<div className="add-document-container">
				<div className="add-document-header">
					<div className="add-document-title-with-img">
						<img src={addDoc} height="100px" width="120px" alt="Add Document" />
						<div className="add-document-title">Add New Document</div>
					</div>
					<ButtonUI
						formButton={false}
						variant="contained"
						buttonText={<AiFillCloseCircle />}
						style={{
							background: "none",
							padding: "0px",
							minWidth: "auto",
						}}
						onClickFunc={onAddDocumentButtonClick}
					></ButtonUI>
				</div>
				<div className="add-document-form">
					<div className="add-document-inputs">
						<div className="add-document-inputs-with-error-message">
							<InputUI
								name="caseID"
								label="Case ID"
								type="text"
								variant="outlined"
								cssStyles={{ margin: "10px 0px" }}
								register={register}
							/>
							<span className="add-doc-error-message">
								{errors["caseID"]?.message}
							</span>
						</div>
						<div className="add-document-inputs-with-error-message">
							<InputUI
								name="documentTitle"
								label="Document Title"
								type="text"
								variant="outlined"
								cssStyles={{ margin: "10px 0px" }}
								register={register}
							/>
							<span className="add-doc-error-message">
								{errors["documentTitle"]?.message}
							</span>
						</div>
					</div>
					<div className="add-document-inputs">
						<div className="add-document-inputs-with-error-message">
							<InputUI
								name="documentDescription"
								label="Document Description"
								type="text"
								variant="outlined"
								cssStyles={{ margin: "10px 0px" }}
								register={register}
							/>
							<span className="add-doc-error-message">
								{errors["documentDescription"]?.message}
							</span>
						</div>
						<div className="add-document-inputs-with-error-message select-ui">
							<FormControl fullWidth>
								<InputLabel id="select-label">Category</InputLabel>
								<Controller
									control={control}
									name="addDocCategories"
									render={({ field: { onChange, value } }) => (
										<Select
											name="addDocCategories"
											sx={{ margin: "10px 0px" }}
											labelId="select-label"
											variant="outlined"
											id="simple-select"
											value={value}
											onChange={onChange}
											label="Category"
											{...register("addDocCategories")}
										>
											{categoryOptions.map((option) => (
												<MenuItem key={option} value={option}>
													{option}
												</MenuItem>
											))}
										</Select>
									)}
								/>
							</FormControl>
							<span className="add-doc-error-message">
								{errors["addDocCategories"]?.message}
							</span>
						</div>
					</div>
					<div className="add-document-inputs">
						<div className="add-document-inputs-with-error-message">
							<InputUI
								name="taxYear"
								label="Tax Year"
								type="text"
								variant="outlined"
								cssStyles={{ margin: "10px 0px" }}
								register={register}
							/>
						</div>
						<div className="add-document-inputs-with-error-message upload-ui">
							<InputUI
								name="fileUpload"
								type="file"
								variant="outlined"
								cssStyles={{
									margin: "10px 0px",
								}}
								register={register}
							/>
							<span className="add-doc-error-message">
								{errors["fileUpload"]?.message}
							</span>
						</div>
					</div>
					<div className="add-document-inputs">
						<FormControl sx={{ marginTop: "10px", width: "49%" }}>
							<InputLabel id="demo-simple-select-label">
								Field Visibility
							</InputLabel>
							<Controller
								control={control}
								name="fieldVisibility"
								defaultValue={0}
								render={({ field: { onChange, value } }) => (
									<Select
										labelId="field-visibility-label"
										id="field-visibility-select"
										name="fieldVisibility"
										value={value}
										onChange={onChange}
										label="Field Visibility"
									>
										<MenuItem value={1}>Yes</MenuItem>
										<MenuItem value={0}>No</MenuItem>
									</Select>
								)}
							/>
						</FormControl>
						<Controller
							control={control}
							name="uploadSource"
							defaultValue="DMS"
							render={({ field }) => (
								<TextField
									{...field}
									id="source"
									name="uploadSource"
									label="Source"
									variant="outlined"
									hidden
								/>
							)}
						/>
					</div>
					<div className="add-document-button">
						<ButtonUI
							loading={loading}
							variant="contained"
							buttonText="Add Document"
							style={{
								width: "150px",
								height: "35px",
								backgroundColor: "#007bff",
								boxShadow: "none",
								border: "1px solid #007bff",
								fontWeight: "500",
								borderRadius: "4px",
								letterSpacing: "0.07rem",
								marginTop: "20px",
							}}
							handleSubmit={handleSubmit}
							onFormSubmit={onAddDocumentFormSubmit}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AddDocument;
