export const Columns = [
	{
		Header: "ID",
		accessor: "documentID",
	},
	{
		Header: "Case ID",
		accessor: "case_number",
	},
	{
		Header: "Tax Year",
		accessor: "tax_year",
	},
	{
		Header: "Starred",
		accessor: "favourite",
	},

	{
		Header: "File",
		accessor: "extension",
	},
	{
		Header: "Name",
		accessor: "title",
	},
	{
		Header: "Document Description",
		accessor: "document_desc",
	},
	{
		Header: "Category Name",
		accessor: "category",
	},
	{
		Header: "Category",
		accessor: "badge",
	},
	{
		Header: "Category Color",
		accessor: "bg_color",
	},
	{
		Header: "Modified By",
		accessor: "modified_user_name",
	},
	{
		Header: "Modified At",
		accessor: "date_modified",
	},
	{
		Header: "Uploaded",
		accessor: "date_entered",
	},
	{
		Header: "Field Visibility",
		accessor: "visibility",
	},
];
